<template>
  <el-container class="home-container">
    <el-header style="height: 50px">
      <div class="title-box">
        <span class="title">捷思联想检验智能管控系统</span>
      </div>
      <div>
        <span class="header-info">{{ nowTime }}</span>
        <span class="header-info" :title="sysUser.roleNames">{{ roleText }}</span>
        <span class="header-info" :title="currentPosition">{{ sysUser.fullName }} 欢迎您</span>
        <el-button type="info" size="mini" @click="logout">退出</el-button>
      </div>
    </el-header>
    <el-main>
      <el-tabs type="border-card">
        <el-tab-pane label="自动检验">
          <AutoTest/>
        </el-tab-pane>
        <el-tab-pane label="整单自动检验">
          <AutoTestTotal/>
        </el-tab-pane>
      </el-tabs>
    </el-main>
  </el-container>
</template>

<script>
import {currentDate} from "@/utils/common";
import AutoTest from "@/views/material/AutoTest";
import AutoTestTotal from "@/views/material/AutoTestTotal";

export default {
  name: "Home",
  components: {
    AutoTestTotal,
    AutoTest,
  },
  data() {
    return {
      nowTime: currentDate(),
      sysUser: {
        fullName: '',
        roleNames: ''
      },
      activePath: ''
    }
  },
  computed: {
    currentPosition() {
      let pos = localStorage.getItem('current_position') || '';
      return '您的工位是：' + pos;
    },
    roleText() {
      let text = this.sysUser.roleNames || '';
      if (text.length > 60) {
        text = text.substring(0, 60) + '...';
      }
      return text;
    }
  },
  methods: {
    logout() {
      this.$confirm('确定退出吗？', '提示', {
        cancelButtonText: '取消',
        confirmButtonText: '确定',
        type: 'warning'
      }).then(() => {
        let token = localStorage.getItem('token');
        if (token) {
          this.$axios.post('invalidToken', {token: token});
        }
        localStorage.removeItem('token');
        this.$router.push('/login')
      })
    },
    saveNavState(path) {
      localStorage.setItem('activePath', path);
      this.activePath = path;
    },
  },
  async created() {
    //加载字典值
    this.$axios.get("sys/selectAll").then(response => {
      if (response.data.code !== 0) {
        this.$message.error('获取字典值失败');
      }
      this.$store.commit("setDictList", response.data.data);
    })

    let {data: res} = await this.$axios.post('/user/getSysUser');
    if (res.code !== 0) {
      this.$message.error(res.message);
      return;
    }
    this.sysUser = res.data;
    this.activePath = localStorage.getItem('activePath')
  },

  mounted() {
    setInterval(() => this.nowTime = currentDate(), 1000);
  }
}
</script>

<style scoped>
.home-container {
  height: 100%;
}

.el-header {
  background-color: #373d41;
  color: #fff;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.el-header .header-info {
  font-size: 14px;
  padding-right: 10px;
}

.el-aside {
  background-color: #333744;
}

.el-main {
  background-color: #eaedf1;
  padding: 5px;
}
</style>