<template>
  <div class="main-box">
    <el-card class="box-card" shadow="never">
      <el-row style="padding-bottom: 10px">
        <el-col :span="24" style="text-align: left;line-height: 40px">
          <el-input v-model.trim="formData.code" placeholder="请输入借出单号" style="width: 350px"></el-input>
          <el-button type="primary" style="margin-left: 10px" :loading="loading" @click="search">查询</el-button>
          <MessageComponent ref="searchMessage"/>
        </el-col>
      </el-row>
      <OrderInfo :order="order"/>
      <el-form :model="formData" label-width="150px">
        <el-row>
          <el-col :span="6">
            <el-form-item label="检验类型：">
              <el-select v-model="formData.sortTestType" placeholder="请选择检验类型" class="form-item">
                <el-option label="外观" value="appearance"></el-option>
                <el-option label="电性能" value="electric"></el-option>
                <el-option label="金钥匙" value="goldenKey"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="检验数量：">
              <el-input v-model="formData.testNum" ref="testNum" type="number" max="10000" min="0" maxlength="5" placeholder="请输入检验数量" class="form-item"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="检验人员账号：">
              <el-select v-model="formData.userId" placeholder="请输入检验人员账号" class="form-item" filterable>
                <el-option v-for="item in userList" :key="item.id" :label="item.fullName+'【'+item.userName+'】'" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <el-form-item label="外观检验结果：">
              <el-radio-group v-model="formData.test1Result">
                <el-radio :label="true">正常</el-radio>
                <el-radio :label="false">不良</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="外观不良代码：" v-show="formData.test1Result===false">
              <el-select v-model="formData.test1TroubleCode" filterable placeholder="请选择外观不良代码" class="form-item">
                <el-option
                    v-for="item in troubleList"
                    :key="item.code"
                    :label="item.code+' '+item.name"
                    :value="item.code"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="外观不良原因：" v-show="formData.test1Result===false">
              <el-input v-model="formData.test1TroubleReason" type="textarea" :autosize="{ minRows: 1, maxRows: 4}" class="form-item"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="外观是否可修复：" v-show="formData.test1Result===false">
              <el-radio-group v-model="formData.test1Repairable">
                <el-radio :label="true">是</el-radio>
                <el-radio :label="false">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="needTest2">
          <el-col :span="6">
            <el-form-item label="电性能检验结果：">
              <el-radio-group v-model="formData.test2Result">
                <el-radio :label="true">正常</el-radio>
                <el-radio :label="false">不良</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="电性能不良代码：" v-show="formData.test2Result===false">
              <el-select v-model="formData.test2TroubleCode" filterable placeholder="请选择电性能不良代码" class="form-item">
                <el-option
                    v-for="item in troubleList"
                    :key="item.code"
                    :label="item.code+' '+item.name"
                    :value="item.code"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="电性能不良原因：" v-show="formData.test2Result===false">
              <el-input v-model="formData.test2TroubleReason" type="textarea" :autosize="{ minRows: 1, maxRows: 4}" class="form-item"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <el-form-item label="检验结果：">
              <el-select v-model="formData.testResult" class="form-item" filterable>
                <el-option
                    v-for="item in testResultList"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="检验结果描述：">
              <el-input v-model="formData.testResultRemark" type="textarea" :autosize="{ minRows: 1, maxRows: 4}" class="form-item"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <el-form-item label="处置意见：">
              <el-select v-model="formData.disposeOpinion" class="form-item" filterable>
                <el-option
                    v-for="item in disposeOpinionList"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="目标库区：">
              <el-select v-model="formData.storeType" class="form-item" filterable>
                <el-option
                    v-for="item in storeTypeList"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-form-item>
          <div style="color: blue">
            注意：系统不考虑任何库区拦截规则，请确保无误后再提交！
          </div>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submit" :loading="loading">提交</el-button>
          <MessageComponent ref="commitMessage"/>
        </el-form-item>
      </el-form>
    </el-card>

  </div>
</template>

<script>
import MessageComponent from "@/components/MessageComponent";
import OrderInfo from "@/views/sortMaterial/OrderInfo";

export default {
  name: "AutoTestTotal",
  components: {MessageComponent, OrderInfo},
  data() {
    return {
      formData: {
        orderId: '',
        code: '',
        sortTestType: '',
        testNum: '',
        userId: '',
        test1Result: undefined,
        test1TroubleCode: '',
        test1Repairable: false,
        test1TroubleReason: '',
        test2Result: undefined,
        test2TroubleCode: '',
        test2TroubleReason: '',
        testResult: '',
        testResultRemark: '',
        disposeOpinion: '',
        storeType: ''
      },
      loading: false,
      order: {},
      userList: [],
      troubleList: [],
    };
  },
  computed: {
    needTest2() {
      return this.formData.sortTestType == 'electric' || this.formData.sortTestType == 'goldenKey';
    },
    disposeOpinionList() {
      return this.$store.getters.getDictList('disposeOpinion') || [];
    },
    testResultList() {
      return this.$store.getters.getDictList('testResult') || [];
    },
    storeTypeList() {
      return this.$store.getters.getDictList('storeType') || [];
    },
  },
  created() {
    this.$axios.get('user/queryTestUser').then(response => {
      if (response.data.code !== 0) {
        return this.$message.error('获取检验人员数据失败');
      }
      this.userList = response.data.data || [];
    })
  },
  methods: {
    resetValue() {
      this.formData.userId = '';
      this.formData.testNum = '';
      this.formData.sortTestType = ''

      this.formData.test1Result = undefined
      this.formData.test1TroubleCode = ''
      this.formData.test1Repairable = false
      this.formData.test1TroubleReason = ''
      this.formData.test2Result = undefined
      this.formData.test2TroubleCode = ''
      this.formData.test2TroubleReason = ''
      this.formData.testResult = ''
      this.formData.testResultRemark = ''
      this.formData.disposeOpinion = ''
      this.formData.storeType = ''
      this.troubleList = [];

    },
    search() {
      this.$refs.searchMessage.clear()
      this.$refs.commitMessage.clear()
      this.order = {};
      this.resetValue()

      if (!this.formData.code) {
        return this.$refs.searchMessage.showError('请输入借出订单号');
      }

      this.loading = true;
      this.$axios.get('/lendOrder/queryForAutoTestByOrder', {
        params: {
          code: this.formData.code
        }
      }).then(response => {
        this.loading = false;
        let res = response.data;
        if (res.code !== 0) {
          return this.$refs.searchMessage.showError(res.message);
        }
        this.order = res.data.lendOrder || {};
        this.troubleList = res.data.troubleList || [];
      }, error => {
        this.loading = false;
        return this.$refs.searchMessage.showError('查询失败，' + error.message);
      })

    },
    submit() {
      this.$refs.commitMessage.clear();
      if (!this.order.id) {
        return this.$refs.commitMessage.showError('请先输入借出订单号查询借出订单信息');
      } else {
        this.formData.orderId = this.order.id
      }
      if (!this.formData.sortTestType) {
        return this.$refs.commitMessage.showError('请选择检验类型');
      }
      if (!this.formData.testNum) {
        return this.$refs.commitMessage.showError('请输入本次要检验的数量');
      } else if (this.formData.testNum < 1) {
        return this.$refs.commitMessage.showError('本次要检验的数量不正确');
      }
      if (!this.formData.userId) {
        return this.$refs.commitMessage.showError('请选择检验人员账号');
      }

      if (this.formData.test1Result !== true && this.formData.test1Result !== false) {
        return this.$refs.commitMessage.showError('请选择外观检验结果');
      }

      if (!this.formData.test1Result) {
        if (!this.formData.test1TroubleCode) {
          return this.$refs.commitMessage.showError('外观不良代码不能为空');
        }

      }

      if (this.needTest2 && this.formData.test2Result !== false && this.formData.test2Result !== true) {
        return this.$refs.commitMessage.showError('请选择电性能检验结果');
      }

      if (this.formData.test2Result === false) {
        if (!this.formData.test2TroubleCode) {
          return this.$refs.commitMessage.showError('请选择电性能不良代码');
        }
      }

      let good = this.formData.test1Result;
      if (this.needTest2) {
        good = this.formData.test1Result && this.formData.test2Result;
      }
      if (!this.formData.testResult) {
        return this.$refs.commitMessage.showError('请选择检验结果');
      } else if (good && this.formData.testResult != '1') {
        return this.$refs.commitMessage.showError('检验结果必须为合格');
      }
      if (!this.formData.disposeOpinion) {
        return this.$refs.commitMessage.showError('请选择处置意见');
      } else if (good && this.formData.disposeOpinion != '20') {
        return this.$refs.commitMessage.showError('处置意见必须为合格');
      }


      if (!this.formData.storeType) {
        return this.$refs.commitMessage.showError('目标库区不能为空')
      }

      this.loading = true
      this.$axios.post('busItem/saveAutoTestByOrder', this.formData).then(response => {
        this.loading = false;
        let res = response.data;
        if (res.code !== 0) {
          return this.$refs.commitMessage.showError(res.message);
        }

        this.$refs.commitMessage.showSuccess('自动检验成功，检验条数：' + res.data);
        this.resetValue();
      }, error => {
        this.loading = false;
        return this.$refs.commitMessage.showError('提交失败，' + error.message);
      })
    }
  }
}
</script>

<style scoped>
.box-card {
  padding-bottom: 100px;
}

.line-tip {
  color: red;
  font-size: 18px;
  padding-right: 10px;
}

.form-item {
  width: 250px;
}

.tip {
  color: goldenrod;
}
</style>